<template>
   <div>
      <div style="position: relative" class="margin">
         <!-- :onloadedmetadata="onPlay" -->
         <video 
            style="position:absolute;top:0;left:0;width:100%;max-height:80vh;right:0;border:1px solid yellow;"
            ref="inputVideo" 
            autoplay muted playsinline></video>
         <canvas ref="overlay" 
            style="position:absolute;top:0;left:0;width:100%;max-height:80vh;right:0;;border:1px solid cyan;"/>

         <div style="position:absolute;z-index:100;padding: 20px; font-sise: 18px; color: white;">
            <div v-if="!loading">
               {{timeSpend}}ms  <span class="ml-4">FPS: {{fps}}</span>
            </div>
            <div v-if="loading">
               Loading recognizer...
               <v-progress-linear
                  indeterminate
                  color="yellow darken-1"
               ></v-progress-linear>
            </div>
            <div class="mt-2 px-2" v-if="recognizing">
               recognizing...
            </div>
         </div>
      </div>
      
   </div>
</template>

<script>
import * as faceapi from '@vladmandic/face-api'
export default {
   data () {
      return {
         modalPath: '/models/',
         SSD_MOBILENETV1: 'ssd_mobilenetv1',
         TINY_FACE_DETECTOR: 'tiny_face_detector',
         selectedFaceDetector: 'ssd_mobilenetv1',
         imgSize: 800,
         minScore: 0.1,
         maxResults: 10,
         minConfidence: 0.2,
         inputSize: 512,
         scoreThreshold: 0.2,
         samples: [
            '/static/sample3.jpg', 
         ],
         forwardTimes: [],
         timeSpend: 0,
         fps: 0,
         loading: true,
         recognizing: false,
      }
   },
   methods: {
      async runWebcam () {
         // await this.changeFaceDetector(this.TINY_FACE_DETECTOR)
         // changeInputSize(128)
         const stream = await navigator.mediaDevices.getUserMedia({ video: {} })
         // console.log(this.$refs)
         const videoEl = this.$refs['inputVideo']
         videoEl.srcObject = stream
      },
      getFaceDetectorOptions() {
         return this.selectedFaceDetector === this.SSD_MOBILENETV1
            ? new faceapi.SsdMobilenetv1Options({ minConfidence: this.minConfidence })
            : new faceapi.TinyFaceDetectorOptions({ inputSize: this.inputSize, scoreThreshold:this.scoreThreshold })
      },
      async onPlay () {
         // console.log("onPlay")
         const videoEl = this.$refs['inputVideo']
         let faceDetectLoad = this.isFaceDetectionModelLoaded()
         this.loading = !faceDetectLoad
         if(!videoEl || videoEl.paused || videoEl.ended || !faceDetectLoad) {
            console.log("rec condition not valid")
            console.log(`videoEl paused ${videoEl?videoEl.paused:'unknown'}`)
            console.log(`videoEl ended ${videoEl?videoEl.ended:'unknown'}`)
            console.log(`faceDetetcLoad ${faceDetectLoad}`)
            return setTimeout(() => this.onPlay())
         }
         const options = this.getFaceDetectorOptions()
         const ts = Date.now()
         this.recognizing = true
         // console.log('faceapi.detectSingleFace')
         const result = await faceapi.detectSingleFace(videoEl, options)
                                    .withFaceLandmarks()
                                    // .withFaceExpressions()
                                    // .withAgeAndGender()

         this.updateTimeStats(Date.now() - ts)
         this.recognizing = false
         // console.log(result)
         if (result) {
            const canvas = this.$refs['overlay']
            const dims = faceapi.matchDimensions(canvas, videoEl, true)
            faceapi.draw.drawDetections(canvas, faceapi.resizeResults(result, dims))
         }

         setTimeout(() => this.onPlay())
      },
      updateTimeStats(timeInMs) {
         this.forwardTimes = [timeInMs].concat(this.forwardTimes).slice(0, 30)
         const avgTimeInMs = this.forwardTimes.reduce((total, t) => total + t) / this.forwardTimes.length
         this.timeSpend = Math.round(avgTimeInMs)
         this.fps = faceapi.utils.round(1000 / avgTimeInMs)
      },
      getCurrentFaceDetectionNet() {
         if (this.selectedFaceDetector === this.SSD_MOBILENETV1) {
            return faceapi.nets.ssdMobilenetv1
         }
         if (this.selectedFaceDetector === this.TINY_FACE_DETECTOR) {
            return faceapi.nets.tinyFaceDetector
         }
      },
      isFaceDetectionModelLoaded() {
         return !!this.getCurrentFaceDetectionNet().params
      },
      async initFaceAPI () {
         console.log('FaceAPI Test')
         await faceapi.tf.setBackend('webgl')
         await faceapi.tf.enableProdMode()
         await faceapi.tf.ENV.set('DEBUG', false)
         await faceapi.tf.ready()

         console.log(`Version: FaceAPI ${faceapi?.version.faceapi || '(not loaded)'} TensorFlow/JS ${faceapi?.tf?.version_core || '(not loaded)'} Backend: ${faceapi?.tf?.getBackend() || '(not loaded)'}`)
         console.log(`Flags: ${JSON.stringify(faceapi?.tf?.ENV.flags || { tf: 'not loaded' })}`)

         await faceapi.nets.tinyFaceDetector.load(this.modalPath)
         await faceapi.nets.ssdMobilenetv1.load(this.modalPath)
         await faceapi.nets.ageGenderNet.load(this.modalPath)
         await faceapi.nets.faceLandmark68Net.load(this.modalPath)
         await faceapi.nets.faceRecognitionNet.load(this.modalPath)
         await faceapi.nets.faceExpressionNet.load(this.modalPath)
         // const optionsTinyFace = new faceapi.TinyFaceDetectorOptions({ inputSize: this.imgSize, scoreThreshold: this.minScore })
         // const optionsSSDMobileNet = new faceapi.SsdMobilenetv1Options({ minConfidence: this.minScore, maxResults: this.maxResults })
      
         const engine = await faceapi.tf.engine()
         console.log(`TF Engine State`)
         console.log(engine.state)
         this.loading = false

         this.onPlay()
      },
      async image(url) {
         return new Promise((resolve) => {
            const img = new Image();
            // wait until image is actually loaded
            img.addEventListener('load', () => {
               // resize image so larger axis is not bigger than limit
               const ratio = 1.0 * img.height / img.width;
               img.width = ratio <= 1 ? this.imgSize : 1.0 * this.imgSize / ratio;
               img.height = ratio >= 1 ? this.imgSize : 1.0 * this.imgSize * ratio;
               // create canvas and draw loaded image
               const canvas = document.createElement('canvas');
               canvas.height = img.height;
               canvas.width = img.width;
               const ctx = canvas.getContext('2d');
               if (ctx) ctx.drawImage(img, 0, 0, img.width, img.height);
               // return generated canvas to be used by tfjs during detection
               resolve(canvas);
            });
            // load image
            img.src = url;
         });
         }
   },
   async created () {
      let self = this
      setTimeout(async function () {
         await self.initFaceAPI()
         await self.runWebcam()
      }, 1000)
   }
}
</script>

<style lang="scss">
   
</style>